import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { CardStackProps } from "./types";
import Card from "../Card";
import clsx from "clsx";

export const CardStack: FC<CardStackProps> = ({ contentList }) => {
  const [items, setItems] = useState(contentList);

  const onSwiped = useCallback(() => {
    const newItems = [...items];
    newItems.pop(); // Remove the last item (the card facing the user)

    // TODO future, have max 3 cards in the stack
    // pop one out, and add a new one

    // some animation

    setItems(newItems);
  }, [items]);

  if (contentList?.length > 0) {
    return (
      <div className="relative w-[80vw] max-w-[22rem] h-[70vh] max-h-[32rem] mr-2 mb-2">
        {items.map((content, index) => {
          return (
            <Card
              key={index}
              text={content}
              className={clsx(
                "absolute",
                index < items.length - 1 && "shadow-none overflow-y-hidden"
              )}
              onCardSwiped={onSwiped}
            />
          );
        })}
        <Card
          className={"ml-2 mt-2 mb-6 overflow-y-hidden bg-white/95"}
          text="That's all folks! You sped through these convos. Click 'reset' to view them again."
          isStatic
        />
        <button
          className="border border-white cursor-pointer rounded-md px-4 py-1.5 tracking-wider font-medium uppercase text-[0.875rem]"
          onClick={items.length > 0 ? onSwiped : () => setItems(contentList)}
        >
          {items.length > 0 ? "Next" : "Reset"}
        </button>
      </div>
    );
  }
  return (
    <div className="relative w-[80vw] max-w-[22rem] h-[70vh] max-h-[32rem] mr-2 mb-2">
      <Card
        isStatic
        className="opacity-95"
        text="Sorry there are no cards in this set. Check back later!"
      />
    </div>
  );
};

export default CardStack;
