import logo from "./assets/adr-logo-plus-superscript.svg";
import CardStack from "./components/CardStack";
import { generalQuestions } from "./constants/questions";

function App() {
  return (
    <main className="bg-blue text-white h-screen w-screen text-center relative">
      <header className="w-full flex items-center justify-between absolute top-0 p-5">
        <img src={logo} className="w-10 text-white" alt="logo" />
        {/* <div className="bg-orange rounded-full w-4 h-4" /> */}
      </header>

      <div className="h-full w-full flex items-center justify-center">
        <CardStack contentList={generalQuestions} />
      </div>
    </main>
  );
}

export default App;
