export const generalQuestions: string[] = [
    "Are you a morning or night productive person?",
    "What is rewarding right now about your work or creative practice?",
    "What's a useful skill you've learned from experience, that a course or degree won't teach you?",
    "Where do you feel most creative?",
    "What do you hope to do more of?",
    "What made you want to work in the creative industry?",
    "What motivates you?",
    "What has been the most challenging project to work on?",
    "What do you know now that you wish you had known earlier?",
    "What is a challenge right now in your work or creative practice?",
    "What's your #1 interview tip?",
    "What is your design pet-peeve?",
    "What would you like to see change in the creative industry?",
    "What have you been experimenting with recently?",
    "What has been the most fulfilling project you've worked on?",
    "What has been the biggest learning curve in your career?",
    "What's your unpopular design opinion?",
    "Who do you look up to, and how have they influenced your work?",
    "What do you want to do less of?",
    "How did you get into this industry?",
    "What's your go-to design tool?",
    "When in the day are you most creative?"
  ]
  
