import { FC, useCallback, useState } from "react";
import { CardProps } from "./types";
import clsx from "clsx";
import { BodyLarge, BodySmall } from "../../text";
import { motion, useMotionValue, useTransform } from "framer-motion";
import logo from "../../assets/adr-text-logo-white.svg";

const VELOCITY_THRESHOLD = 50;
const OFFSET_THRESHOLD = 50;

export const Card: FC<CardProps> = ({
  text,
  className,
  isStatic,
  onCardSwiped,
}) => {
  const x = useMotionValue(0);
  const rotateValue = useTransform(x, [-1000, 0, 0, 1000], [-4, 0, 0, 4]);
  const [isShowingInfo, setIsShowingInfo] = useState(false);

  const toggleInfo = useCallback(() => {
    setIsShowingInfo(!isShowingInfo);
  }, [isShowingInfo]);

  if (isStatic) {
    return (
      <div
        className={clsx(
          "bg-white text-blue w-full h-full rounded-xl shadow-lg",
          "p-10 pb-20 text-left overflow-y-auto overflow-x-hidden",
          className
        )}
      >
        <BodyLarge>{text}</BodyLarge>
      </div>
    );
  }

  return (
    <motion.div
      drag="x"
      dragConstraints={{ left: -20, right: 20 }}
      onDrag={(event, info) => {
        x.set(info.offset.x);
      }}
      onDragEnd={(event, info) => {
        const offset = info.offset.x;
        const velocity = info.velocity.x;

        if (
          (offset > OFFSET_THRESHOLD || offset < -OFFSET_THRESHOLD) &&
          (velocity > VELOCITY_THRESHOLD || velocity < -VELOCITY_THRESHOLD)
        ) {
          // TODO animate out
          onCardSwiped && onCardSwiped();
        }
      }}
      whileDrag={{ scale: 0.95 }}
      style={{ rotate: rotateValue }}
      className={clsx(
        "w-full h-full rounded-xl shadow-lg",
        "p-10 text-left overflow-y-auto cursor-pointer",
        isShowingInfo
          ? "text-white bg-blue border border-current"
          : "bg-white text-blue",
        className
      )}
    >
      <button
        onClick={toggleInfo}
        className={clsx(
          "text-sm border border-current bg-white rounded-full",
          "flex items-center justify-center absolute bottom-4 right-4 w-6 h-6",
          isShowingInfo ? "text-white" : "text-orange"
        )}
      >
        {isShowingInfo ? (
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.81342 10.5411L9.91948 10.6471L10.0255 10.5411L10.5411 10.0255L10.6472 9.91942L10.5411 9.81335L6.2278 5.5L10.5411 1.18665L10.6472 1.08058L10.5411 0.974516L10.0255 0.458917L9.91948 0.352851L9.81342 0.458916L5.50006 4.77227L1.18671 0.458917L1.08065 0.352851L0.97458 0.458917L0.458981 0.974517L0.352915 1.08058L0.458981 1.18665L4.77233 5.5L0.458981 9.81335L0.352915 9.91942L0.458981 10.0255L0.97458 10.5411L1.08065 10.6471L1.18671 10.5411L5.50006 6.22773L9.81342 10.5411Z"
              fill="#5000FF"
              stroke="#5000FF"
              strokeWidth="0.3"
            />
          </svg>
        ) : (
          "?"
        )}
      </button>
      {isShowingInfo && (
        <img
          src={logo}
          className="w-full mb-5 min-h-[0.75rem]"
          alt="Text logo of Australian Design Radio"
        />
      )}
      <BodyLarge>{!isShowingInfo && text}</BodyLarge>
      {isShowingInfo && (
        <>
          <BodySmall className="mb-4">
            We know striking a conversation isn't easy. You already got yourself
            here in a room full of creatives, but now you have to talk to them!?
          </BodySmall>
          <BodySmall className="mb-4">
            Swipe the card for a new prompt, and use these prompts to start a
            conversation with a fellow creative. Go on... don't be scared, I'm
            sure they won't ask you to do any work for 'exposure'.
          </BodySmall>
          <hr className="mb-5 opacity-70" />
          <BodySmall>
            Please be gentle, this is v0.0.1 of our webapp 🤍 If you have any
            feedback for us,{" "}
            <a className="underline" href="mailto:hello@ausdesignradio.com">
              get in touch
            </a>
            .
          </BodySmall>
        </>
      )}
    </motion.div>
  );
};

export default Card;
